import React from 'react';
import { Box, Drawer, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, useTheme } from '@mui/material';

interface EditorNavbarIconProps {
  text: string,
  isMobile: boolean,
  hoverColor: string,
  onClick: () => void;
  children: React.ReactNode;
}

const EditorNavbarIcon: React.FC<EditorNavbarIconProps> = ({text, isMobile, hoverColor, onClick, children}) => {
    const theme = useTheme();

    return (
        <Tooltip title="" placement="right">
          <ListItem
            button
            onClick={() => onClick()}
            sx={{
              marginTop: isMobile ? '0px' : '30px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: isMobile ? '68px' : '100%',
              height: isMobile ? '100%' : '68px',
              transition: 'all 0.3s ease-in-out',
              '&:hover .MuiSvgIcon-root': {
                color: isMobile ? '' : hoverColor,  // Icon color on hover
              }
            }}
          >
            <ListItemIcon sx={{ justifyContent: 'center', minWidth: '0' }}>
              {children}
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body2" sx={{ fontSize: '0.7rem', color: '#7f7f7f' }}>{text}</Typography>}
              sx={{ fontSize: '2rem', marginTop: isMobile ? '0px' : '8px' }}
            />
          </ListItem>
        </Tooltip>
    );
}

export default EditorNavbarIcon;