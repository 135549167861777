import React, { useState, useRef, useEffect } from 'react';
import { Box, Card } from '@mui/material';
import ProjectMenu from './menus/ProjectMenu';
import MediaMenu from './menus/MediaMenu';
import AudioMenu from './menus/AudioMenu';
import FiltersMenu from './menus/FiltersMenu';
import TextMenu from './menus/TextMenu';
import SubtitlesMenu from './menus/SubtitlesMenu';
import EditorNavbar from './EditorNavbar';
import TextMedia from './media/Text';
import Media from './media/Media';
import Audio from './media/Audio';
import Video from './media/Video';

type EffectSelectorProps = {
  selectedVideo: Media | null,
  canvasBackgroundColor: [number, number, number, number],
  videoStart: number | null,
  videoEnd: number | null,
  isMobileLayout: boolean; // This is given and cannot be changed
  onSelect: (effectName: string) => void;
  onAddMedia: (file: File) => void;
  onChangeBackground: (color: [number, number, number, number]) => void;
  onMediaChanged: (media: Video, start: number | null, end: number | null) => void;
  onTextCreated: (text: string, style: any) => void;
  onTextStyleChanged: (text: string, style: any) => void;
  onSubtitlesGenerate: (style: any) => void;
  onSubtitlesUpload: (file: File) => void;
};

const mobileMenuSize = 400;
const desktopMenuSize = 400;

const EffectSelector: React.FC<EffectSelectorProps> = ({
  selectedVideo,
  canvasBackgroundColor,
  videoStart,
  videoEnd,
  isMobileLayout,
  onSelect,
  onAddMedia,
  onChangeBackground,
  onMediaChanged,
  onTextCreated,
  onTextStyleChanged,
  onSubtitlesGenerate,
  onSubtitlesUpload,
}) => {
  const [isOpen, setIsOpen] = useState(isMobileLayout ? false : true);
  const [activeMedia, setActiveMedia] = useState<{media: Media | null, activeMenu: string | null}>({media: null, activeMenu: null});

  // State variables for touch and mouse events
  const [startY, setStartY] = useState(0);
  const [panelHeight, setPanelHeight] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleRef = useRef<HTMLDivElement>(null); // Ref for the drag handle

  useEffect(() => {
    if ((selectedVideo instanceof Video)) {
      // for now mobile layout does not have media menu
      if (!isMobileLayout) {
        setIsOpen(true);
        if (isMobileLayout) {
          setPanelHeight(mobileMenuSize);
        }
      }
      setActiveMedia({media: selectedVideo, activeMenu: 'media'});
    }
    else if (selectedVideo instanceof Audio && !isMobileLayout) {
      if (!isMobileLayout) {
        setIsOpen(true);
        if (isMobileLayout) {
          setPanelHeight(mobileMenuSize);
        }
      }
      setActiveMedia({media: selectedVideo, activeMenu: 'audio'});
    }
    else if (selectedVideo instanceof TextMedia) {
      // for now auto open disabled on mobile
      if (!isMobileLayout) {
        setIsOpen(true);
        if (isMobileLayout) {
          setPanelHeight(mobileMenuSize);
        }
        setActiveMedia({media: selectedVideo, activeMenu: 'text'});
      } else {
        setActiveMedia({...activeMedia, media: selectedVideo});
      }
    } else {
      setActiveMedia({media: null, activeMenu: isMobileLayout ? null : 'settings'});
    }
  }, [selectedVideo])

  useEffect(() => {
  }, [canvasBackgroundColor]);
  useEffect(() => {
  }, [activeMedia]);
  useEffect(() => {
  }, [videoStart, videoEnd]);

  const handleAddMedia = (file: File | undefined) => {
    console.log(file);
    if (file) {
      onAddMedia(file);
    }
    closePanel();
  };

  const handleOpenFileDialog = () => {
      if (fileInputRef.current) {
          fileInputRef.current.click();
      }
  };

  const handleMenuClick = (menuName: string | null) => {
    // For now only mobile has an opening navbar
    if (isMobileLayout && activeMedia?.activeMenu === menuName) {
      setActiveMedia({...activeMedia, activeMenu: null})
      setIsOpen(false);
      setPanelHeight(0);
      return;
    }
    setActiveMedia({...activeMedia, activeMenu: menuName})
    setIsOpen(true);
    if (isMobileLayout) {
      setPanelHeight(mobileMenuSize);
    }
  };

  // Add event listeners for touch and mouse events
  useEffect(() => {
    if (!isMobileLayout) return;

    const handleElement = handleRef.current;
    if (!handleElement) return;

    const handleDragStart = (clientY: number) => {
      setIsDragging(true);
      setStartY(clientY);
    };

    const onTouchStart = (e: TouchEvent) => {
      if (e.target !== handleElement && !handleElement.contains(e.target as Node)) {
        return;
      }
      e.preventDefault();
      handleDragStart(e.touches[0].clientY);
    };

    const onMouseDown = (e: MouseEvent) => {
      if (e.target !== handleElement && !handleElement.contains(e.target as Node)) {
        return;
      }
      e.preventDefault();
      handleDragStart(e.clientY);
    };

    handleElement.addEventListener('touchstart', onTouchStart, { passive: false });
    handleElement.addEventListener('mousedown', onMouseDown);

    // Cleanup function
    return () => {
      handleElement.removeEventListener('touchstart', onTouchStart);
      handleElement.removeEventListener('mousedown', onMouseDown);
    };
  }, [isMobileLayout]);

  // Add move and end listeners only when dragging
  useEffect(() => {
    if (!isDragging) return;

    const handleDragMove = (clientY: number) => {
      const deltaY = startY - clientY;

      setPanelHeight((prevHeight) => {
        let newHeight = prevHeight + deltaY;

        // Clamp the new height between 0 and mobileMenuSize
        newHeight = Math.max(0, Math.min(newHeight, mobileMenuSize));

        return newHeight;
      });

      setStartY(clientY); // Update startY for smooth dragging
    };

    const handleDragEnd = () => {
      setIsDragging(false);

      setPanelHeight((prevHeight) => {
        if (prevHeight > mobileMenuSize / 2) {
          setIsOpen(true);
          return mobileMenuSize;
        } else {
          setIsOpen(false);
          setActiveMedia({...activeMedia, activeMenu: null})
          return 0;
        }
      });
    };

    const onTouchMove = (e: TouchEvent) => {
      e.preventDefault();
      handleDragMove(e.touches[0].clientY);
    };

    const onTouchEnd = () => {
      handleDragEnd();
    };

    const onMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      handleDragMove(e.clientY);
    };

    const onMouseUp = () => {
      handleDragEnd();
    };

    document.addEventListener('touchmove', onTouchMove, { passive: false });
    document.addEventListener('touchend', onTouchEnd);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    // Cleanup
    return () => {
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging, startY]);

  const closePanel = () => {
    // For now, only mobile layout has opening and closing navbar
    if (isMobileLayout) {
      setIsOpen(false);
      setActiveMedia({...activeMedia, activeMenu: null})
      if (isMobileLayout) {
        setPanelHeight(0);
      }
    }
  }

  return (
    <Box
      sx={{
        height: isMobileLayout ? 'auto' : '100%',
        width: isMobileLayout ? '100%' : 'auto',
        display: 'flex',
        flexDirection: isMobileLayout ? 'column' : 'row',
        position: 'relative',
        zIndex: 100,
        borderRight: isMobileLayout ? '' : '1px solid #ddd'
      }}
    >
      {/* Desktop Navbar */}
      {!isMobileLayout && (
        <EditorNavbar
          selectedItem={activeMedia.activeMenu}
          isMobileNavbar={isMobileLayout}
          onMenuIconClicked={handleMenuClick}
          onMediaClicked={handleOpenFileDialog}
        />
      )}

      {/* Backdrop Overlay */}
      {isMobileLayout && (isOpen || panelHeight > 0) && (
        <Box
          onTouchStart={(e: React.TouchEvent) => closePanel()}
          onClick={(e: React.MouseEvent) => closePanel()}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
            zIndex: 100, // Behind the panel but above other content
            pointerEvents: 'auto', // Ensure it captures clicks
          }}
        />
      )}

      {/* Side Panel */}
      <Box
        sx={{
          height: isMobileLayout ? 'auto' : '100%',
          display: 'flex',
          position: isMobileLayout ? 'fixed' : 'relative',
          zIndex: 101, // Above the backdrop
        }}
      >
        <Card
          sx={{
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            position: isMobileLayout ? 'fixed' : 'relative',
            bottom: '0px',
            left: isMobileLayout ? '0px' : 'unset',
            width: isMobileLayout ? '100%' : isOpen ? `${desktopMenuSize}px` : '0px',
            height: isMobileLayout ? `${panelHeight}px` : '100%',
            margin: '0px',
            transition: isMobileLayout ? 'height 0.3s ease' : 'width 0.3s ease',
            zIndex: 101, // Above the backdrop
          }}
        >
          {/* Drag Handle for Mobile */}
          {isMobileLayout && (
            <Box
              ref={handleRef}
              sx={{
                width: '100%',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'grab',
                touchAction: 'none', // Prevent default scrolling
                userSelect: 'none', // Prevent text selection
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  height: '4px',
                  borderRadius: '2px',
                  backgroundColor: '#ccc',
                }}
              />
            </Box>
          )}

          {/* Panel Content */}
          {(isOpen || panelHeight > 0) && (
            <Box sx={{height: '100%', paddingBottom: isMobileLayout ? '80px' : '0px', overflow: 'hidden'}}>
              {(activeMedia.activeMenu === 'settings') &&
                <ProjectMenu
                  backgroundColor={canvasBackgroundColor}
                  onChangeBackground={onChangeBackground}
                  onAddMedia={handleAddMedia}
                  projectDuration={"auto"}
                  projectName={"hello"}
                  onDurationChange={() => {}}
                  onFpsChange={() => {}}
                  fps={30}
                />}
              {(activeMedia.activeMenu === 'media') &&
                <MediaMenu
                  mediaStart={videoStart}
                  mediaEnd={videoEnd}
                  selectedMedia={(activeMedia.media instanceof Video) ? {media: activeMedia.media, start: videoStart || 0, end: videoEnd || 0} : null}
                  canvasBackgroundColor={canvasBackgroundColor}
                  isMobileLayout={isMobileLayout}
                  onMediaChanged={onMediaChanged}
                  onChangeBackground={onChangeBackground}
                  onAddMedia={handleAddMedia}
                />}
              {(activeMedia.activeMenu === 'audio') &&
                <AudioMenu
                  mediaStart={videoStart}
                  mediaEnd={videoEnd}
                  selectedMedia={(activeMedia.media instanceof Audio) ? activeMedia.media : null}
                  isMobileLayout={isMobileLayout}
                  onTranscribe={() => {}}
                  onRecord={() =>{}}
                  onMediaChanged={onMediaChanged}
                  onAddMedia={handleAddMedia}
                />}
              {activeMedia.activeMenu === 'filters' && <FiltersMenu onSelect={onSelect} isMobileLayout={isMobileLayout} selectedVideo={activeMedia.media instanceof Video ? activeMedia.media : null} />}
              {activeMedia.activeMenu === 'text' && <TextMenu isMobile={isMobileLayout} onTextCreated={onTextCreated} onTextStyleSelect={onTextStyleChanged} selectedMedia={activeMedia.media instanceof TextMedia ? activeMedia.media : null} />}
              {activeMedia.activeMenu === 'subtitles' && <SubtitlesMenu onSubtitlesGenerate={onSubtitlesGenerate} onSubtitlesUpload={onSubtitlesUpload} onSubtitlesStyleSelect={() => {}} selectedElement={activeMedia.media} />}
            </Box>
          )}
        </Card>
      </Box>

      {/* Mobile Navbar */}
      {isMobileLayout && (
        <EditorNavbar
          selectedItem={activeMedia.activeMenu}
          isMobileNavbar={isMobileLayout}
          onMenuIconClicked={handleMenuClick}
          onMediaClicked={handleOpenFileDialog}
        />
      )}
    <input
        ref={fileInputRef}
        type="file"
        accept="video/*,audio/*,image/*"
        style={{ display: 'none' }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddMedia(event?.target.files?.[0])}
    />
    </Box>
  );
};

export default EffectSelector;
