import { Box, IconButton, Button, CircularProgress } from '@mui/material';
import { DownloadOutlined, Undo, Redo } from '@mui/icons-material';
import CommandHistory from './undo/UndoInterface';

import EditorIconButton from './EditorIconButton';
import AspectRatioSelector from './AspectRatioSelector';
import AppBar from './AppBar';

interface EditorTopBarProps {
    isMobile: boolean;
    isDownloading: boolean;
    canvasAspectRatio: number;
    handleAspectRatioSelected: (newRatio: number) => void;
    handleDownloadVideo: () => void;
}

const EditorTopBar: React.FC<EditorTopBarProps> = ({canvasAspectRatio, isDownloading, isMobile, handleAspectRatioSelected, handleDownloadVideo}) => {
    return (
        <AppBar title={''} color="inherit" isEditing={false} onTitleChange={() => {}}>
          <Box sx={{display: 'flex', flexGrow: 1, height: '30px', alignItems: 'center', justifyContent: 'left'}}>
            <EditorIconButton isMobile={isMobile} onClick={() => CommandHistory.undo()}>
              <Undo />
            </EditorIconButton>
            <EditorIconButton isMobile={isMobile} onClick={() => CommandHistory.redo()}>
              <Redo/>
            </EditorIconButton>
            {!isMobile && (
              <AspectRatioSelector isMobile={isMobile} onSelect={handleAspectRatioSelected} selectedRatio={canvasAspectRatio} />
            )}
          </Box>
          <Box sx={{display: 'flex', height: '30px', alignItems: 'center', justifyContent: 'right'}}>
            <Button
              disabled={isDownloading}
              variant='outlined'
              color='primary'
              component='span'
              sx={{
                marginLeft: 2,
              }}
              onClick={() => handleDownloadVideo()}>
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {isDownloading ? (
                  <>
                  <CircularProgress size={24} />
                  <span style={{marginLeft: 10}}>Rendering</span>
                  </>
                ) : (
                  <>
                  <DownloadOutlined sx={{color: '#888888'}}/>
                  <span style={{marginLeft: 10}}>Done</span>
                  </>
                )}
              </Box>
            </Button>
          </Box>
        </AppBar>
    );
}

export default EditorTopBar;
