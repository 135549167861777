import React, { useState } from 'react';
import {
  Typography,
  Box,
  Divider,
  Paper,
  Button,
  Popover,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Slider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import MovieIcon from '@mui/icons-material/Movie';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SpeedIcon from '@mui/icons-material/Speed';
import { ChromePicker } from 'react-color';

// Styled Components
const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  height: '100%',
  overflowY: 'auto',
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

interface ProjectMenuProps {
  onAddMedia: (file: File) => void;
  onChangeBackground: (color: [number, number, number, number]) => void;
  onDurationChange: (duration: number | 'auto') => void;
  onFpsChange: (fps: number) => void;
  projectName: string;
  projectDuration: number | 'auto';
  backgroundColor: [number, number, number, number];
  fps: number;
}

const ProjectMenu: React.FC<ProjectMenuProps> = ({
  onAddMedia,
  onChangeBackground,
  onDurationChange,
  onFpsChange,
  projectName,
  projectDuration,
  backgroundColor,
  fps,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [durationMode, setDurationMode] = useState<'auto' | 'manual'>(
    projectDuration === 'auto' ? 'auto' : 'manual'
  );
  const [manualDuration, setManualDuration] = useState<number>(
    typeof projectDuration === 'number' ? projectDuration : 60
  );

  const handleFileChange = (
    type: 'video' | 'image' | 'audio',
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      onAddMedia(file);
    }
  };

  const handleColorButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColorChange = (color: any) => {
    onChangeBackground([
      color.rgb.r / 255,
      color.rgb.g / 255,
      color.rgb.b / 255,
      color.rgb.a,
    ]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDurationModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const mode = event.target.value as 'auto' | 'manual';
    setDurationMode(mode);
    if (mode === 'auto') {
      onDurationChange('auto');
    } else {
      onDurationChange(manualDuration);
    }
  };

  const handleManualDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setManualDuration(value);
    if (durationMode === 'manual') {
      onDurationChange(value);
    }
  };

  const handleFpsChange = (event: Event, newValue: number | number[]) => {
    onFpsChange(newValue as number);
  };

  return (
    <Container sx={{ direction: 'ltr' }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: 'bold', color: 'text.primary' }}
      >
        Project Settings
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {/*
      <Section elevation={3}>
        <SectionHeader>Project Information</SectionHeader>
        <Typography variant="body1">
          <strong>Project Name:</strong> {projectName}
        </Typography>
        <Typography variant="body1">
          <strong>Duration:</strong>{' '}
          {projectDuration === 'auto' ? 'Automatic' : `${projectDuration} seconds`}
        </Typography>
        <Typography variant="body1">
          <strong>Frame Rate:</strong> {fps} FPS
        </Typography>
      </Section>
      */}

      {/* Project Duration */}
      <Section elevation={3}>
        <SectionHeader>
          <AccessTimeIcon sx={{ marginRight: 1, color: 'primary.main' }} />
          Project Duration
        </SectionHeader>
        <RadioGroup
          row
          value={durationMode}
          onChange={handleDurationModeChange}
          sx={{ marginBottom: 2 }}
        >
          <FormControlLabel disabled value="auto" control={<Radio />} label="Automatic" />
          <FormControlLabel disabled value="manual" control={<Radio />} label="Manual" />
        </RadioGroup>
        {durationMode === 'manual' && (
          <TextField
            label="Duration (seconds)"
            type="number"
            value={manualDuration}
            onChange={handleManualDurationChange}
            fullWidth
          />
        )}
      </Section>

      {/* Frame Rate */}
      <Section elevation={3}>
        <SectionHeader>
          <SpeedIcon sx={{ marginRight: 1, color: 'primary.main' }} />
          Frame Rate
        </SectionHeader>
        <Box display="flex" alignItems="center">
          <Slider
            disabled
            value={fps}
            min={1}
            max={60}
            step={1}
            onChange={handleFpsChange}
            valueLabelDisplay="auto"
            sx={{ flexGrow: 1 }}
          />
          <Typography variant="body1" sx={{ marginLeft: 2 }}>
            {fps} FPS
          </Typography>
        </Box>
      </Section>

      {/* Change Background */}
      <Section elevation={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <SectionHeader>
            <ColorLensIcon sx={{ marginRight: 1, color: 'primary.main' }} />
            Change Background
          </SectionHeader>

          {/* Circle showing the selected background color */}
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: `rgba(${backgroundColor[0] * 255}, ${
                backgroundColor[1] * 255
              }, ${backgroundColor[2] * 255}, ${backgroundColor[3]})`,
              border: '2px solid #ccc',
              cursor: 'pointer',
            }}
            onClick={handleColorButtonClick}
          />
        </Box>

        {/* Color Picker Popover */}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ChromePicker
            color={`rgba(${backgroundColor[0] * 255}, ${backgroundColor[1] * 255}, ${
              backgroundColor[2] * 255
            }, ${backgroundColor[3]})`}
            onChange={handleColorChange}
          />
        </Popover>
      </Section>
    </Container>
  );
};

export default ProjectMenu;
