const backendUrl = process.env.REACT_APP_DEBUG_BUILD ? 'http://localhost:3000' : 'https://app.zevideoai.com/api';
const frontendUrl = process.env.REACT_APP_DEBUG_BUILD ? 'http://localhost:3030' : 'https://app.zevideoai.com';

console.log(process.env);
const config = {
    SERVER_ADDRESS: backendUrl,
    FRONTEND_ADDRESS: frontendUrl,
    LOGIN_PATH: "/login" as const,
    REGISTER_VIA_GOOGLE_OAUTH_PATH: "/oauth/google" as const,
    REGISTER_VIA_MICROSOFT_OAUTH_PATH: "/oauth/microsoft" as const,
    SET_VIDEO_UPLOADED_PATH: "/videouploaded" as const,
    GET_VIDEO_INFO_PATH: "/video" as const,
    GET_SIGNED_UPLOAD_URL_PATH: "/uploadurl" as const,
    GET_SIGNED_SUBTITLES_UPLOAD_URL_PATH: "/subtitles/uploadurl" as const,
    GET_SIGNED_SUBTITLES_DOWNLOAD_URL_PATH: "/subtitles/downloadurl" as const,
    GET_RENDER_TASKS_PATH: "/tasks/render" as const,
    GET_SUBTITLES_TASKS_PATH: "/tasks/subtitles" as const,
    DOWNLOAD_VIDEO_PATH: "/download" as const,
    DOWNLOAD_SUBTITLES_PATH: "/tasks/subtitles" as const,
    UPDATE_PROFILE_PATH: "/updateprofile" as const,
    ADD_INTEGRATION_PATH: "/addintegration" as const,
    RESET_PASSWORD_PATH: "/resetpassword" as const,
    RESET_PASSWORD_VIA_TOKEN_PATH: "/resetpasswordviatoken" as const,
    REMOVE_INTEGRATION_PATH: "/removeintegration" as const,
    REMOVE_EXPORT_PATH: "/removeexport" as const,
    REMOVE_USER_PATH: "/removeuser" as const,
    LIST_INTEGRATIONS_PATH: "/listintegrations" as const,
    LIST_SOURCES_PATH: "/listsources" as const,
    LIST_DESTINATIONS_PATH: "/listdestinations" as const,
    LIST_TOKENS_PATH: "/listtokens" as const,
    REGISTER_USER_VIA_TOKEN_PATH: "/tokenregistration" as const,
    REGISTER_USER_PATH: "/register" as const,
    RESEND_REGISTRATION: "/resendregistration" as const,
    ACTIVATE_USER_VIA_TOKEN_PATH: "/activate" as const,
    CONFIGURE_EXPORT_PATH: "/configureexport" as const,
    CONFIGURE_TOKEN_PATH: "/configuretoken" as const,
    CONFIGURE_TOKEN_ASSET_PATH: "/configuretokenasset" as const,
    LIST_TOKEN_ASSETS_PATH: "/listtokenassets" as const,
    FETCH_LAYOUT_PATH: "/fetchlayout" as const,
    SAVE_LAYOUT_PATH: "/savelayout" as const,
    FETCH_CHART_DATA_PATH: "/fetchdata" as const,
    FETCH_DASHBOARDS_PATH: "/fetchdashboards" as const,
    FETCH_USERS_PATH: "/fetchusers" as const,
    FETCH_HOME_METRICS_PATH: "/fetchhomemetrics" as const,
    FETCH_REMOTE_EXPORTS_PATH: "/fetchremoteexports" as const,
    FETCH_LOCAL_EXPORTS_PATH: "/fetchlocalexports" as const,
    FETCH_RAW_DATA_PATH: "/fetchrawdata" as const,
    REMOVE_DASHBOARD_PATH: "/removedashboard" as const,
    CHECK_AUTH_PATH: "/check-auth" as const,
    ADD_USER_PATH: "/adduser" as const,
    ADD_EXPORT_PATH: "/addexport" as const,
    LOGOUT_PATH: "/logout" as const,
    frontendUrl,
};

export default config;
