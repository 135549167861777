import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Divider,
  Button,
  CircularProgress,
  TextField,
  Slider,
  ToggleButton,
  Tooltip,
  Popover,
  Autocomplete,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormControl,
  Paper,
} from '@mui/material';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  VerticalAlignTop,
  VerticalAlignCenter,
  VerticalAlignBottom,
  Delete,
  CloudUpload,
  Subtitles as SubtitlesIcon,
  AutoFixHigh,
  UploadFile,
  TextFields,
} from '@mui/icons-material';
import { ChromePicker, ColorResult } from 'react-color';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  height: '100%',
  overflowY: 'auto',
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

interface SubtitleEntry {
  id: string;
  startTime: string;
  endTime: string;
  text: string;
}

interface TextStyle {
  color: string;
  backgroundColor: string;
  fontSize: number;
  textAlign: CanvasTextAlign;
  verticalAlign: string;
  fontFamily: string;
  fontWeight: 'normal' | 'bold' | 'lighter';
  fontStyle: 'normal' | 'italic';
  textDecoration: 'none' | 'underline';
}

const initialTextStyle: TextStyle = {
  color: '#FFFFFF',
  backgroundColor: 'transparent',
  fontSize: 24,
  textAlign: 'center',
  verticalAlign: 'middle',
  fontFamily: 'Roboto',
  fontWeight: 'normal',
  fontStyle: 'normal',
  textDecoration: 'none',
};

interface SubtitlesMenuProps {
  selectedElement: any;
  onSubtitlesGenerate: (style: React.CSSProperties) => void;
  onSubtitlesUpload: (file: File) => void;
  onSubtitlesStyleSelect: (subtitles: SubtitleEntry[], style: React.CSSProperties) => void;
}

const SubtitlesMenu: React.FC<SubtitlesMenuProps> = ({
  selectedElement,
  onSubtitlesGenerate,
  onSubtitlesUpload,
  onSubtitlesStyleSelect,
}) => {
  const theme = useTheme();

  const [color, setColor] = useState<string>(initialTextStyle.color);
  const [backgroundColor, setBackgroundColor] = useState<string>(initialTextStyle.backgroundColor);
  const [fontSize, setFontSize] = useState<number>(initialTextStyle.fontSize);
  const [textAlign, setTextAlign] = useState<CanvasTextAlign>(initialTextStyle.textAlign);
  const [verticalAlign, setVerticalAlign] = useState<string>(initialTextStyle.verticalAlign);
  const [fontFamily, setFontFamily] = useState<string>(initialTextStyle.fontFamily);
  const [fontWeight, setFontWeight] = useState<'normal' | 'bold' | 'lighter'>(
    initialTextStyle.fontWeight
  );
  const [fontStyle, setFontStyle] = useState<'normal' | 'italic'>(initialTextStyle.fontStyle);
  const [textDecoration, setTextDecoration] = useState<'none' | 'underline'>(
    initialTextStyle.textDecoration
  );

  const [anchorElTextColor, setAnchorElTextColor] = useState<null | HTMLElement>(null);
  const [anchorElBgColor, setAnchorElBgColor] = useState<null | HTMLElement>(null);

  const [subtitles, setSubtitles] = useState<SubtitleEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [subtitleFileName, setSubtitleFileName] = useState<string>('');

  const hasSubtitles = selectedElement?.subtitles != null;

  const resetState = () => {
    setColor(initialTextStyle.color);
    setBackgroundColor(initialTextStyle.backgroundColor);
    setFontSize(initialTextStyle.fontSize);
    setTextAlign(initialTextStyle.textAlign);
    setVerticalAlign(initialTextStyle.verticalAlign);
    setFontFamily(initialTextStyle.fontFamily);
    setFontWeight(initialTextStyle.fontWeight);
    setFontStyle(initialTextStyle.fontStyle);
    setTextDecoration(initialTextStyle.textDecoration);
    setSubtitles([]);
    setSubtitleFileName('');
  };

  useEffect(() => {
    if (!hasSubtitles) {
      resetState();
    }
  }, [selectedElement, hasSubtitles]);

  useEffect(() => {
    if (hasSubtitles) {
      const { subtitles, style } = selectedElement.subtitles;

      setSubtitles(subtitles || []);
      setFontSize(parseInt(style?.fontSize?.toString() || initialTextStyle.fontSize.toString()));
      setColor(style?.color || initialTextStyle.color);
      setBackgroundColor(style?.backgroundColor || initialTextStyle.backgroundColor);
      setFontFamily(style?.fontFamily || initialTextStyle.fontFamily);
      setFontWeight(
        (style?.fontWeight as 'normal' | 'bold' | 'lighter') || initialTextStyle.fontWeight
      );
      setFontStyle((style?.fontStyle as 'normal' | 'italic') || initialTextStyle.fontStyle);
      setTextDecoration(
        (style?.textDecoration as 'none' | 'underline') || initialTextStyle.textDecoration
      );
      setTextAlign((style?.textAlign as CanvasTextAlign) || initialTextStyle.textAlign);
      setVerticalAlign((style?.verticalAlign as any) || initialTextStyle.verticalAlign);
    } else {
      resetState();
    }
  }, [selectedElement, hasSubtitles]);

  useEffect(() => {
    if (hasSubtitles) {
      const updatedStyle: React.CSSProperties = {
        fontSize: `${fontSize}px`,
        color: color,
        backgroundColor: backgroundColor,
        textAlign: textAlign,
        verticalAlign: verticalAlign,
        fontFamily: fontFamily,
        fontWeight: fontWeight,
        fontStyle: fontStyle,
        textDecoration: textDecoration,
      };
      onSubtitlesStyleSelect(subtitles, updatedStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subtitles,
    fontSize,
    color,
    backgroundColor,
    textAlign,
    fontFamily,
    fontWeight,
    fontStyle,
    textDecoration,
    verticalAlign,
  ]);

  const generateSubtitles = () => {
    const initialStyle: React.CSSProperties = {
      fontSize: `${fontSize}px`,
      color: color,
      backgroundColor: backgroundColor,
      textAlign: textAlign,
      verticalAlign: verticalAlign,
      fontFamily: fontFamily,
      fontWeight: fontWeight,
      fontStyle: fontStyle,
      textDecoration: textDecoration,
    };

    setLoading(true);

    // Simulate subtitle generation delay
    setTimeout(() => {
      // Mock generated subtitles
      const generatedSubtitles: SubtitleEntry[] = [
        {
          id: uuidv4(),
          startTime: '00:00:01,000',
          endTime: '00:00:05,000',
          text: 'Welcome to our video.',
        },
        {
          id: uuidv4(),
          startTime: '00:00:06,000',
          endTime: '00:00:10,000',
          text: 'We hope you enjoy it.',
        },
        // Add more mock subtitles as needed
      ];

      setSubtitles(generatedSubtitles);
      setLoading(false);

    }, 3000); // Simulate a 3-second generation time
    onSubtitlesGenerate(initialStyle);
  };

  const handleDeleteSubtitle = (id: string) => {
    setSubtitles(subtitles.filter((subtitle) => subtitle.id !== id));
  };

  // Handle file upload
  const handleUploadSubtitles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSubtitleFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        // Parse the subtitle file (assuming SRT format)
        const parsedSubtitles = parseSRT(text);
        setSubtitles(parsedSubtitles);
        // Notify parent component that subtitles have been created
        const initialStyle: React.CSSProperties = {
          fontSize: `${fontSize}px`,
          color: color,
          backgroundColor: backgroundColor,
          textAlign: textAlign,
          verticalAlign: verticalAlign,
          fontFamily: fontFamily,
          fontWeight: fontWeight,
          fontStyle: fontStyle,
          textDecoration: textDecoration,
        };
        //onSubtitlesGenerate(parsedSubtitles, initialStyle);
      };
      reader.readAsText(file);

      onSubtitlesUpload(file);
    }
  };

  const parseSRT = (data: string): SubtitleEntry[] => {
    const regex =
      /(\d+)\s+(\d{2}:\d{2}:\d{2}[,.:]\d{3})\s*-->?\s*(\d{2}:\d{2}:\d{2}[,.:]\d{3})\s+([\s\S]*?)(?=\n{2,}|$)/gm;
    let result;
    const entries: SubtitleEntry[] = [];

    while ((result = regex.exec(data)) !== null) {
      const [_, index, startTime, endTime, text] = result;
      entries.push({
        id: uuidv4(),
        startTime: startTime.replace('.', ',').trim(),
        endTime: endTime.replace('.', ',').trim(),
        text: text.trim().replace('\r', ''),
      });
    }

    return entries;
  };

  // Color Picker Handlers
  const handleTextColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTextColor(event.currentTarget);
  };

  const handleBgColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElBgColor(event.currentTarget);
  };

  const handleTextColorClose = () => {
    setAnchorElTextColor(null);
  };

  const handleBgColorClose = () => {
    setAnchorElBgColor(null);
  };

  const handleTextColorChange = (colorResult: ColorResult) => {
    const { r, g, b, a } = colorResult.rgb;
    setColor(`rgba(${r},${g},${b},${a})`);
  };

  const handleBgColorChange = (colorResult: ColorResult) => {
    const { r, g, b, a } = colorResult.rgb;
    if (a === 0) {
      setBackgroundColor('transparent');
    } else {
      setBackgroundColor(`rgba(${r},${g},${b},${a})`);
    }
  };

  // Font options
  const fontOptions = [
    'Roboto',
    'Arial',
    'Helvetica',
    'Times New Roman',
    'Courier New',
    'Verdana',
    'Georgia',
    'Palatino',
    'Garamond',
    'Bookman',
    'Comic Sans MS',
    'Trebuchet MS',
    'Arial Black',
    'Impact',
  ];

  // Render generate subtitles option
  const renderGenerateSubtitlesOption = () => (
    <>
      <Section elevation={3}>
        <SectionHeader>
          <SubtitlesIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
          Generate Subtitles
        </SectionHeader>
        <Typography variant="body1" gutterBottom>
          Automatically generate subtitles for your video.
        </Typography>
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <Button
            disabled={!(
              selectedElement?.hasAudio() &&
              selectedElement?.onLoadedCalled && // finished loading
              selectedElement?.uploadComplete && // finished upload to the server (have the file in S3)
              !selectedElement?.downloadingSubtitles && // no subtitles currently downloading
              !selectedElement?.subtitlesLoaded // no subtitles already exist for this media
            )}
            variant="contained"
            color="primary"
            onClick={generateSubtitles}
            startIcon={<AutoFixHigh />}
            sx={{
              width: '100%',
              maxWidth: '300px',
              height: '50px',
              fontSize: '1rem',
            }}
          >
            {selectedElement?.downloadingSubtitles ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ marginRight: 2 }} />
                Generating...
              </>
            ) : (
              'Generate Subtitles'
            )}
          </Button>
          {selectedElement?.downloadingSubtitles && (
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              Generating subtitles, please wait...
            </Typography>
          )}
        </Box>
      </Section>

      <Section elevation={3}>
        <SectionHeader>
          <CloudUpload sx={{ marginRight: 1, color: theme.palette.primary.main }} />
          Upload Subtitles
        </SectionHeader>
        <Typography variant="body1" gutterBottom>
          Upload your own subtitles file (.vtt).
        </Typography>
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <input
            accept=".vtt"
            style={{ display: 'none' }}
            id="upload-subtitles-file"
            type="file"
            onChange={handleUploadSubtitles}
          />
          <label htmlFor="upload-subtitles-file">
            <Button
              disabled={!(
                selectedElement?.hasAudio() &&
                selectedElement?.onLoadedCalled && // finished loading
                selectedElement?.uploadComplete && // finished upload to the server (have the file in S3)
                !selectedElement?.downloadingSubtitles && // no subtitles currently downloading
                !selectedElement?.subtitlesLoaded // no subtitles already exist for this media
              )}
              variant="outlined"
              color="primary"
              component="span"
              startIcon={<UploadFile />}
              sx={{
                width: '100%',
                maxWidth: '300px',
                height: '50px',
                fontSize: '1rem',
              }}
            >
              Upload Subtitles File
            </Button>
          </label>
          {subtitleFileName && (
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              Uploaded file: {subtitleFileName}
            </Typography>
          )}
        </Box>
      </Section>
    </>
  );

  // Render customization options for existing subtitles
  const renderCustomizationOptions = () => (
    <>
      <Section elevation={3}>
        <SectionHeader>
          <TextFields sx={{ marginRight: 1, color: theme.palette.primary.main }} />
          Edit Subtitles
        </SectionHeader>
        <List sx={{ maxHeight: 300, overflow: 'auto', marginBottom: 2 }}>
          {subtitles.map((subtitle, index) => (
            <ListItem key={subtitle.id} disableGutters>
              <ListItemText
                primary={`[${subtitle.startTime} - ${subtitle.endTime}]`}
                secondary={
                  <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    size="small"
                    value={subtitle.text}
                    onChange={(e) => {
                      const updatedSubtitles = [...subtitles];
                      updatedSubtitles[index].text = e.target.value;
                      setSubtitles(updatedSubtitles);
                    }}
                  />
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteSubtitle(subtitle.id)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Section>

      <Section elevation={3}>
        <SectionHeader>
          <FormatBold sx={{ marginRight: 1, color: theme.palette.primary.main }} />
          Style Subtitles
        </SectionHeader>

        {/* Font Family Selector */}
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <Autocomplete
            options={fontOptions}
            value={fontFamily}
            onChange={(e, newValue) => {
              if (newValue) setFontFamily(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Font Family" variant="outlined" />}
            getOptionLabel={(option) => option as string}
            renderOption={(props, option) => (
              <Box component="li" {...props} style={{ fontFamily: option }}>
                {option}
              </Box>
            )}
          />
        </FormControl>

        {/* Font Size Slider */}
        <Typography gutterBottom variant="subtitle1">
          Font Size: <strong>{fontSize}px</strong>
        </Typography>
        <Slider
          value={fontSize}
          min={12}
          max={100}
          onChange={(e, newValue) => setFontSize(newValue as number)}
          valueLabelDisplay="auto"
          sx={{ marginBottom: 3 }}
        />

        {/* Font Styles */}
        <Box sx={{ display: 'flex', gap: 1, marginBottom: 3 }}>
          <Tooltip title="Bold">
            <ToggleButton
              value="bold"
              selected={fontWeight === 'bold'}
              onClick={() => setFontWeight(fontWeight === 'bold' ? 'normal' : 'bold')}
            >
              <FormatBold />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Italic">
            <ToggleButton
              value="italic"
              selected={fontStyle === 'italic'}
              onClick={() => setFontStyle(fontStyle === 'italic' ? 'normal' : 'italic')}
            >
              <FormatItalic />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Underline">
            <ToggleButton
              value="underline"
              selected={textDecoration === 'underline'}
              onClick={() =>
                setTextDecoration(textDecoration === 'underline' ? 'none' : 'underline')
              }
            >
              <FormatUnderlined />
            </ToggleButton>
          </Tooltip>
        </Box>

        {/* Text Alignment */}
        <Box sx={{ display: 'flex', gap: 1, marginBottom: 3 }}>
          <Tooltip title="Align Left">
            <ToggleButton
              value="left"
              selected={textAlign === 'left'}
              onClick={() => setTextAlign('left')}
            >
              <FormatAlignLeft />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Align Center">
            <ToggleButton
              value="center"
              selected={textAlign === 'center'}
              onClick={() => setTextAlign('center')}
            >
              <FormatAlignCenter />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Align Right">
            <ToggleButton
              value="right"
              selected={textAlign === 'right'}
              onClick={() => setTextAlign('right')}
            >
              <FormatAlignRight />
            </ToggleButton>
          </Tooltip>
        </Box>

        {/* Text Vertical Alignment */}
        <Box sx={{ display: 'flex', gap: 1, marginBottom: 3 }}>
          <Tooltip title="Vertical Align Top">
            <ToggleButton
              value="top"
              selected={verticalAlign === 'top'}
              onClick={() => setVerticalAlign('top')}
            >
              <VerticalAlignTop />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Vertical Align Center">
            <ToggleButton
              value="center"
              selected={verticalAlign === 'middle'}
              onClick={() => setVerticalAlign('middle')}
            >
              <VerticalAlignCenter />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Vertical Align Bottom">
            <ToggleButton
              value="bottom"
              selected={verticalAlign === 'bottom'}
              onClick={() => setVerticalAlign('bottom')}
            >
              <VerticalAlignBottom />
            </ToggleButton>
          </Tooltip>
        </Box>

        {/* Color Pickers */}
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 3 }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" gutterBottom>
              Text Color
            </Typography>
            <Box
              sx={{
                width: 36,
                height: 36,
                backgroundColor: color,
                borderRadius: '50%',
                border: '1px solid #ccc',
                cursor: 'pointer',
                margin: '0 auto',
              }}
              onClick={handleTextColorClick}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
            />
            <Popover
              open={Boolean(anchorElTextColor)}
              anchorEl={anchorElTextColor}
              onClose={handleTextColorClose}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <ChromePicker color={color} onChange={handleTextColorChange} disableAlpha={false} />
            </Popover>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" gutterBottom>
              Background Color
            </Typography>
            <Box
              sx={{
                width: 36,
                height: 36,
                backgroundColor: backgroundColor === 'transparent' ? '#f0f0f0' : backgroundColor,
                borderRadius: '50%',
                border: '1px solid #ccc',
                cursor: 'pointer',
                margin: '0 auto',
              }}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
              onClick={handleBgColorClick}
            />
            <Popover
              open={Boolean(anchorElBgColor)}
              anchorEl={anchorElBgColor}
              onClose={handleBgColorClose}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <ChromePicker
                color={backgroundColor === 'transparent' ? 'rgba(255,255,255,0)' : backgroundColor}
                onChange={handleBgColorChange}
                disableAlpha={false}
              />
            </Popover>
          </Box>
        </Box>
      </Section>
    </>
  );

  return (
    <Container>
      <Box style={{ direction: 'ltr' }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
        >
          Subtitles
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        {
        renderGenerateSubtitlesOption()
        //selectedElement && selectedElement.subtitlesActive ?
        //    renderCustomizationOptions() :
        //    renderGenerateSubtitlesOption()
        }
      </Box>
    </Container>
  );
};

export default SubtitlesMenu;
