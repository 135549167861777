import React, { useEffect } from 'react';
import { Box, Drawer, useTheme } from '@mui/material';
import { Settings, MusicNote, Subtitles, PhotoFilter, VideoLibrary, TextFields } from '@mui/icons-material';

import EditorNavbarIcon from './EditorNavbarIcon';

interface NavbarProps {
  selectedItem: string | null;
  isMobileNavbar: boolean;
  onMenuIconClicked: (menuName: string) => void;
  onMediaClicked: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ selectedItem, isMobileNavbar, onMenuIconClicked, onMediaClicked }) => {
  const theme = useTheme();
  const drawerWidth = isMobileNavbar ? 60 : 70;

  useEffect(() => {
  }, [selectedItem]);

  const renderMediaIcon = () => {
    return (
        <EditorNavbarIcon text="Media" hoverColor={theme.palette.secondary.main} isMobile={isMobileNavbar} onClick={isMobileNavbar ? () => onMediaClicked() : () => onMenuIconClicked('media')}>
            <VideoLibrary
              sx={{
                color: isMobileNavbar ? '#e57373' : (selectedItem === 'media' ? theme.palette.secondary.main : '#7f7f7f'),
                fontSize: 24,
                transition: 'color 0.2s ease',
              }}
            />
        </EditorNavbarIcon>
    )
  }

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%', display: 'flex', overflow: 'hidden' }}>
      <Drawer
        sx={{
          overflow: 'hidden',
          width: isMobileNavbar ? '100%' : drawerWidth,
          height: isMobileNavbar ? drawerWidth : '100%',
          flexDirection: isMobileNavbar ? 'row' : 'column',
          flexGrow: 1,
          '& .MuiDrawer-paper': {
            display: 'flex',
            justifyContent: isMobileNavbar ? 'space-between' : 'start',
            flexDirection: isMobileNavbar ? 'row' : 'column',
            position: 'relative',
            overflow: 'hidden',
            width: isMobileNavbar ? '100%' : drawerWidth,
            paddingLeft: isMobileNavbar ? 1 : 0,
            paddingRight: isMobileNavbar ? 1 : 0,
            height: isMobileNavbar ? drawerWidth : '100%',
            boxSizing: 'border-box',
            background: '#ffffff',  // White background for light theme
            boxShadow: 'none',  // Cleaner look without heavy shadows
          },
          alignItems: 'center',
          justifyContent: 'center',
        }}
        variant="permanent"
      >
        {!isMobileNavbar && (
        <EditorNavbarIcon text="Settings" hoverColor={theme.palette.primary.main} isMobile={isMobileNavbar} onClick={() => onMenuIconClicked('settings')}>
          <Settings
            sx={{
              color: selectedItem === 'settings' ? theme.palette.primary.main : '#7f7f7f',
              fontSize: 24,
              transition: 'color 0.2s ease',
            }}
          />
        </EditorNavbarIcon>
        )}
        {!isMobileNavbar && renderMediaIcon()}
        <EditorNavbarIcon text="Text" hoverColor='#81c784' isMobile={isMobileNavbar} onClick={() => onMenuIconClicked('text')}>
          <TextFields
            sx={{
              color: selectedItem === 'text' ? '#81c784' : '#7f7f7f',
              fontSize: 24,
              transition: 'color 0.2s ease',
            }}
          />
        </EditorNavbarIcon>
        <EditorNavbarIcon text="Audio" hoverColor={'#FFCA28'} isMobile={isMobileNavbar} onClick={() => onMenuIconClicked('audio')}>
          <MusicNote
            sx={{
              color: selectedItem === 'audio' ? '#FFCA28' : '#7f7f7f',
              fontSize: 24,
              transition: 'color 0.2s ease',
            }}
          />
        </EditorNavbarIcon>
        {isMobileNavbar && renderMediaIcon()}
        <EditorNavbarIcon text="Subtitles" hoverColor={theme.palette.info.main} isMobile={isMobileNavbar} onClick={() => onMenuIconClicked('subtitles')}>
          <Subtitles
            sx={{
              color: selectedItem === 'subtitles' ? theme.palette.info.main : '#7f7f7f',
              fontSize: 24,
              transition: 'color 0.2s ease',
            }}
          />
        </EditorNavbarIcon>
        <EditorNavbarIcon text="Filters" hoverColor={'#80CBC4'} isMobile={isMobileNavbar} onClick={() => onMenuIconClicked('filters')}>
          <PhotoFilter
            sx={{
              color: selectedItem === 'filters' ? '#80CBC4' : '#7f7f7f',
              fontSize: 24,
              transition: 'color 0.2s ease',
            }}
          />
        </EditorNavbarIcon>
      </Drawer>
    </Box>
  );
};

export default Navbar;
