import { v4 as uuidv4 } from 'uuid';

type Crop = {
  left: number,
  right: number,
  top: number,
  bottom: number
}

export default abstract class Media {
    public id: string;
    public name: string;
    public sha256: string;
    public type: string;
    public url: string;
    public videoRef: any;
    public row: number;
    public left: number;
    public leftBackgroundOffset: number;
    public rightBackgroundOffset: number;
    public startBase: number;
    public endBase: number;
    public leftShrink: number;
    public rightShrink: number;
    public duration: number;
    public isResizing: boolean;
    public dirty: boolean;
    public playOffset: number;
    public framesOffset: number;
    public frameCutOffset: number;
    public onLoadedCalled: boolean;
    public effects: string[];
    public height: number;
    public width: number;
    public aspectRatio: number;
    public scaledHeight: number | null;
    public scaledWidth: number | null;
    public baseHeight: number;
    public baseWidth: number;
    public x: number;
    public y: number;
    public rotation: number;
    public crop: Crop;
    public resizable: boolean;
    public cropable: boolean;
    public frames: any[];
    public audioFrames: any[];
    public uploadComplete: boolean;
    public speed: number;
    public hasVideoComponent: boolean;
    public hasAudioComponent: boolean;

    constructor ({
        id = uuidv4(),
        name,
        sha256,
        url,
        type,
        row,
        left,
        leftBackgroundOffset,
        rightBackgroundOffset,
        startBase,
        endBase,
        leftShrink,
        rightShrink,
        videoRef,
        duration,
        isResizing = false,
        dirty = false,
        playOffset = 0,
        framesOffset = 0,
        frameCutOffset = 0,
        onLoadedCalled = false,
        effects = [],
        height,
        width,
        scaledHeight = null,
        scaledWidth = null,
        baseHeight = 0,
        baseWidth = 0,
        x,
        y,
        rotation,
        crop,
        resizable,
        cropable,
        frames = [],
        audioFrames = [],
        uploadComplete = false,
        speed = 1,
        hasVideoComponent,
        hasAudioComponent,
    }: {
        id?: string,
        name: string,
        sha256: string;
        url: string;
        type: string;
        row: number,
        left: number,
        leftBackgroundOffset: number,
        rightBackgroundOffset: number,
        startBase: number,
        endBase: number,
        leftShrink: number,
        rightShrink: number,
        videoRef: any;
        duration: number,
        isResizing: boolean,
        dirty: boolean,
        playOffset?: number;
        framesOffset?: number;
        frameCutOffset?: number;
        onLoadedCalled?: boolean;
        effects?: string[];
        height: number;
        width: number;
        scaledHeight: number | null;
        scaledWidth: number | null;
        baseHeight: number;
        baseWidth: number;
        x: number;
        y: number;
        rotation: number;
        crop: Crop;
        resizable: boolean;
        cropable: boolean;
        frames?: any[];
        audioFrames?: any[];
        uploadComplete?: boolean;
        speed: number;
        hasVideoComponent: boolean;
        hasAudioComponent: boolean;
    }) {
        this.id = id ? id : uuidv4();
        this.name = name;
        this.sha256 = sha256;
        this.url = url;
        this.type = type;
        this.row = row;
        this.left = left;
        this.leftBackgroundOffset = leftBackgroundOffset;
        this.rightBackgroundOffset = rightBackgroundOffset;
        this.startBase = startBase;
        this.endBase = endBase;
        this.leftShrink = leftShrink;
        this.rightShrink = rightShrink;
        this.videoRef = videoRef;
        this.duration = duration;
        this.isResizing = isResizing;
        this.dirty = dirty;
        this.framesOffset = framesOffset;
        this.frameCutOffset = frameCutOffset;
        this.playOffset = playOffset;
        this.onLoadedCalled = onLoadedCalled;
        this.effects = effects;
        this.height = height;
        this.width = width;
        this.aspectRatio = width / height;
        this.scaledHeight = scaledHeight;
        this.scaledWidth = scaledWidth;
        this.baseHeight = baseHeight;
        this.baseWidth = baseWidth;
        this.x = x;
        this.y = y;
        this.rotation = rotation;
        this.crop = {... crop};
        this.resizable = resizable;
        this.cropable = cropable;
        this.frames = frames;
        this.audioFrames = audioFrames;
        this.uploadComplete = uploadComplete;
        this.speed = speed;
        this.hasVideoComponent = hasVideoComponent;
        this.hasAudioComponent = hasAudioComponent;
    }
    abstract getEffectString(prependVideoNormalization: boolean): string;
    abstract requiresWasm() : boolean;
    abstract hasVideo() : boolean;
    abstract hasAudio() : boolean;
    abstract audioEnabled() : boolean;
    abstract deepCopy() : any;
    abstract getSerializable() : any;
}

export type {
    Crop
}
