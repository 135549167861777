import Media, { Crop } from './Media';
import { effectNameToStringMap } from '../Effect';

export default class Text extends Media {
    text: string;
    textWidth: number;
    textHeight: number;
    style: React.CSSProperties;

    constructor ({
        id,
        name,
        sha256,
        url,
        type,
        text,
        row,
        duration,
        left,
        leftBackgroundOffset, 
        rightBackgroundOffset, 
        leftShrink, 
        rightShrink, 
        videoRef,
        startBase, 
        endBase, 
        isResizing, 
        dirty, 
        framesOffset, 
        playOffset, 
        onLoadedCalled,
        effects,
        height,
        width,
        scaledHeight,
        scaledWidth,
        baseHeight,
        baseWidth,
        x,
        y,
        rotation,
        crop,
        style,
        textWidth,
        textHeight,
    }: {
        id?: string,
        name: string,
        sha256: string,
        url: string,
        type: string,
        text: string,
        row: number,
        left: number,
        leftBackgroundOffset: number,
        rightBackgroundOffset: number,
        startBase: number,
        endBase: number,
        leftShrink: number,
        rightShrink: number,
        videoRef: any;
        duration: number,
        isResizing: boolean,
        dirty: boolean,
        playOffset?: number;
        framesOffset?: number;
        onLoadedCalled?: boolean;
        effects?: string[];
        height: number;
        width: number;
        scaledHeight: number | null;
        scaledWidth: number | null;
        baseHeight: number;
        baseWidth: number;
        x: number;
        y: number;
        rotation: number;
        crop: Crop;
        style: React.CSSProperties;
        textWidth: number;
        textHeight: number;
    }) {
        super({
            id, 
            name,
            sha256,
            url,
            type,
            row, 
            duration, 
            left, 
            leftBackgroundOffset, 
            rightBackgroundOffset, 
            leftShrink, 
            rightShrink, 
            videoRef,
            startBase, 
            endBase, 
            isResizing, 
            dirty, 
            framesOffset, 
            playOffset, 
            onLoadedCalled,
            effects,
            height,
            width,
            scaledHeight,
            scaledWidth,
            baseHeight,
            baseWidth,
            x,
            y,
            rotation,
            crop,
            resizable: true,
            cropable: false,
            uploadComplete: true,
            speed: 1,
            hasVideoComponent: true,
            hasAudioComponent: false,
        });
        
        this.text = text;
        this.style = style;
        this.textWidth = textWidth;
        this.textHeight = textHeight;
    }

    hasVideo = (): boolean => {
        return true;
    }

    hasAudio = (): boolean => {
        return false;
    }

    audioEnabled = (): boolean => {
        return false;
    }

    getEffectString = (prependVideoNormalization: boolean = false): string => {
        return `scale=${this.scaledWidth}:${this.scaledHeight}`;
    }

    deepCopy = ():  Text => {
        return new Text({...this});
    }

    getSerializable = (): Text => {
        const cleanText = this.deepCopy();
        cleanText.videoRef = null;
        return cleanText;
    }

    requiresWasm = (): boolean => {
        return false;
    }
}
