
import { effectNameToStringMap } from '../Effect';
import getEngine from '../EffectsEngine';
import Media from './Media';

const Engine = await getEngine();

type shrinkType = 'shrinkLeft' | 'shrinkRight' | 'increaseLeft' | 'increaseRight' | null;

type Crop = {
  left: number,
  right: number,
  top: number,
  bottom: number
}

export default class Audio extends Media {
  subtitlesLoaded: boolean;
  subtitlesActive: boolean;
  downloadingSubtitles: boolean;
  storageFilePath: string;
  storageBuffer: Uint8Array | null;
  videoFdRef: number | null;
  isPlaying: boolean;
  framePendingTime: number | null;
  curVideoTime: number;
  hasVideoComponent: boolean;
  hasAudioComponent: boolean;
  isMuted: boolean;
  uploadProgress: number;
  volume: number;

  // Constructor using named parameters
  constructor({
    id,
    sha256,
    name,
    type,
    url,
    subtitlesLoaded = false,
    subtitlesActive = false,
    downloadingSubtitles = false,
    storageFilePath,
    storageBuffer = null,
    duration,
    left = 0,
    leftBackgroundOffset = 0,
    rightBackgroundOffset = 0,
    startBase = 0,
    endBase,
    isResizing = false,
    leftShrink = 0,
    rightShrink = 0,
    videoRef = null,
    videoFdRef = null,
    isPlaying = false,
    framePendingTime = null,
    playOffset = 0,
    framesOffset = 0,
    curVideoTime = 0,
    row,
    effects = [],
    hasVideoComponent = false,
    hasAudioComponent = false,
    isMuted = false,
    uploadProgress = 0,
    height,
    width,
    scaledHeight,
    scaledWidth,
    baseHeight,
    baseWidth,
    x,
    y,
    rotation,
    crop,
    dirty = false,
    onLoadedCalled,
    frames = [],
    audioFrames = [],
    uploadComplete,
    speed = 1,
    volume = 1,
  }: {
    id?: string;
    sha256: string;
    name: string;
    type: string;
    url: string;
    subtitlesLoaded?: boolean;
    subtitlesActive?: boolean;
    downloadingSubtitles?: boolean;
    storageFilePath: string;
    storageBuffer?: Uint8Array | null;
    duration: number;
    left?: number;
    leftBackgroundOffset?: number;
    rightBackgroundOffset?: number;
    startBase?: number;
    endBase: number;
    isResizing?: boolean;
    leftShrink?: number;
    rightShrink?: number;
    videoRef?: any;
    videoFdRef?: number | null;
    isPlaying?: boolean;
    framePendingTime?: number | null;
    playOffset?: number;
    framesOffset?: number;
    curVideoTime?: number;
    row: number;
    effects?: string[];
    dirty?: boolean;
    height: number;
    width: number;
    scaledHeight: number | null;
    scaledWidth: number | null;
    baseHeight: number;
    baseWidth: number;
    x: number;
    y: number;
    rotation: number;
    crop: Crop;
    onLoadedCalled?: boolean;
    hasVideoComponent?: boolean;
    hasAudioComponent?: boolean;
    isMuted?: boolean;
    uploadProgress?: number;
    frames?: any[],
    audioFrames?: any[],
    uploadComplete?: boolean,
    opacity?: number;
    hue?: number;
    brightness?: number;
    volume?: number;
    speed?: number,
  }) {
    super({
      id, 
      name,
      sha256,
      url,
      type,
      row, 
      duration, 
      left, 
      leftBackgroundOffset, 
      rightBackgroundOffset, 
      leftShrink, 
      rightShrink, 
      videoRef,
      startBase, 
      endBase, 
      isResizing, 
      dirty, 
      framesOffset, 
      playOffset, 
      onLoadedCalled,
      effects,
      height,
      width,
      scaledHeight,
      scaledWidth,
      baseHeight,
      baseWidth,
      x,
      y,
      rotation,
      crop,
      resizable: false,
      cropable: false,
      frames,
      audioFrames,
      uploadComplete,
      speed,
      hasVideoComponent: false,
      hasAudioComponent: true
    });
    // always create new to prevent two videos using the same storage
    let newStorage;
    if (storageBuffer) {
      // TODO: should we copy the memory?
      newStorage = new Uint8Array(storageBuffer.length);
    } else {
      newStorage = new Uint8Array();
    }
    this.subtitlesLoaded = subtitlesLoaded;
    this.subtitlesActive = subtitlesActive;
    this.downloadingSubtitles = downloadingSubtitles;
    this.storageFilePath = storageFilePath;
    this.storageBuffer = newStorage;
    this.videoRef = videoRef;
    this.videoFdRef = videoFdRef;
    this.isPlaying = isPlaying;
    this.framePendingTime = framePendingTime;
    this.curVideoTime = curVideoTime;
    this.hasVideoComponent = hasVideoComponent;
    this.hasAudioComponent = hasAudioComponent;
    this.isMuted = isMuted;
    this.uploadProgress = uploadProgress;
    this.volume = volume;
  }

  hasVideo = (): boolean => {
    return this.hasVideoComponent;
  }

  hasAudio = (): boolean => {
    return this.hasAudioComponent;
  }

  audioEnabled = (): boolean => {
    return this.hasAudio() && !this.isMuted;
  }

  // Delete all existing effects and replace with the given effect
  setEffect = async (effect: string): Promise<boolean> => {
    if (effect in effectNameToStringMap) {
      const index = this.effects.indexOf(effect);
      if (index === -1) {
        // delete all effects and replace with this effect
        this.effects = [effect];
      } else {
        // remove the existing effect
        this.effects = [];
      }

      const effectString = this.getEffectString();
      const res = await Engine.set_effect(this.videoFdRef as number, this.scaledWidth as number, this.scaledHeight as number, effectString).then((res: number) => {
        return res;
      });

      if (res == -1) {
        console.error("failed to set effect");
        return false;
      }
      return true;
    }

    return false;
  }

  // Method to add an effect to the video
  addEffect = async (effect: string): Promise<boolean> => {
    if (effect in effectNameToStringMap) {
      const index = this.effects.indexOf(effect);
      if (index === -1) {
        this.effects.push(effect);
      } else {
        // remove the effect
        this.effects.splice(index, 1);
      }

      const effectString = this.getEffectString();
      const res = await Engine.set_effect(this.videoFdRef as number, this.scaledWidth as number, this.scaledHeight as number, effectString).then((res: number) => {
        return res;
      });

      if (res == -1) {
        console.error("failed to set effect");
        return false;
      }
      return true;
    }

    return false;
  }

  getEffectString = (prependVideoNormalization: boolean = false): string => {
    const cropOutWOffset = this.crop.right + this.crop.left;
    let cropWString = '';
    if (cropOutWOffset !== 0) {
      cropWString = cropOutWOffset > 0 ? `+${cropOutWOffset}` : `${cropOutWOffset}`;
    }
    const cropOutHOffset = this.crop.top + this.crop.bottom;
    let cropHString = '';
    if (cropOutHOffset !== 0) {
      cropHString = cropOutHOffset > 0 ? `+${cropOutHOffset}` : `${cropOutHOffset}`;
    }

    // TODO: fix that scaledHeight and scaledWidth are not null
    let cropLeft = 0;
    let cropRight = 0;
    let cropTop = 0;
    let cropBottom = 0;
    if (this.scaledHeight && this.scaledWidth) {
      cropLeft = Math.ceil(this.crop.left * this.scaledWidth);
      cropRight = Math.ceil(this.crop.right * this.scaledWidth);
      if (prependVideoNormalization) {
        cropBottom = Math.ceil(this.crop.top * this.scaledHeight);
        cropTop = Math.ceil(this.crop.bottom * this.scaledHeight);
      } else{
        cropBottom = Math.ceil(this.crop.bottom * this.scaledHeight);
        cropTop = Math.ceil(this.crop.top * this.scaledHeight);
      }
    }

    return (
      (prependVideoNormalization ? [`scale=${this.scaledWidth}:${this.scaledHeight}`] : [])
      .concat(
      //this.rotation ? [`rotate=${this.rotation}*PI/180:ow=rotw(iw):oh=roth(ih):c=black, pad='iw*sqrt(2)':'ih*sqrt(2)':(ow-iw)/2:(oh-ih)/2`] : [],
      this.rotation ? [`rotate=${this.rotation}*PI/180`] : [],
      (this.crop.top || this.crop.bottom || this.crop.left || this.crop.right) ?
        [`crop=${(Math.max((this.scaledWidth || 0)-cropRight-cropLeft, 1))}:${Math.max((this.scaledHeight || 0)-cropTop-cropBottom, 1)}:${cropLeft}:${cropBottom}`] :
        [],
      this.effects.map((effectName: string) => effectNameToStringMap[effectName]),
      // scale the image to the current size at the end
      `scale=${this.scaledWidth}:${this.scaledHeight}:flags=fast_bilinear`
      )
      .join(','));
  }

  setCrop = async (direction: 'left' | 'right' | 'top' | 'bottom', offset: number) => {
    this.crop[direction] = offset;
    const effectString = this.getEffectString();
    const res = await Engine.set_effect(this.videoFdRef as number, this.scaledWidth as number, this.scaledHeight as number, effectString).then((res: number) => {
      return res;
    });
    return res;
  }

  deepCopy = (): Audio => {
    let newStorage;
    if (this.storageBuffer) {
      // TODO: should we copy the memory?
      newStorage = new Uint8Array(this.storageBuffer.length);
    } else {
      newStorage = new Uint8Array();
    }

    return new Audio({
      ...this,
      // add all properties that need to be deepcopied (reference and not values)
      crop: { left: this.crop.left, right: this.crop.right, top: this.crop.top, bottom: this.crop.bottom },
      frames: this.frames.map(frame => ({ ...frame })),
      audioFrames: this.audioFrames.map(frame => ({ ...frame }))
    });
  }

  getSerializable = (): Audio => {
    const cleanVideo = this.deepCopy();
    cleanVideo.storageBuffer = null;
    cleanVideo.videoRef = null;
    cleanVideo.frames = [];
    cleanVideo.audioFrames = [];
    return cleanVideo;
  }

  requiresWasm = (): boolean => {
      return false;
  }
}

export {
  type shrinkType
}