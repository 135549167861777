import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Typography,
  Box,
  useTheme,
  Slider,
  Divider,
  Button,
  Paper,
} from '@mui/material';
import { throttle } from 'lodash';
import { styled } from '@mui/material/styles';
import SpeedIcon from '@mui/icons-material/Speed';
import OpacityIcon from '@mui/icons-material/Opacity';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import TimerIcon from '@mui/icons-material/Timer';
import Video from '../media/Video';
import Audio from '../media/Audio';
import TimeInput from '../TimeInput';
import EmptyMediaMenu from './EmptyMediaMenu';

// Styled Components
const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  height: '100%',
  overflowY: 'auto',
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

const ControlLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: theme.palette.grey[400],
  },
}));

// Define the interface for SpeedButton's transient prop
interface SpeedButtonProps {
  $active: boolean;
}

// Styled SpeedButton using transient prop $active
const SpeedButton = styled(Button)<SpeedButtonProps>(({ theme, $active }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: $active ? theme.palette.primary.main : theme.palette.grey[300],
  color: $active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: $active ? theme.palette.primary.dark : theme.palette.grey[400],
  },
  minWidth: 50, // Consistent button width
}));

type MediaAttributes = {
  speed: number;
  opacity: number | undefined;
  hue: number | undefined;
  brightness: number | undefined;
  volume: number;
  start: number;
  end: number;
}

type SelectedMediaType = {
  media: Video | Audio | null;
  start: number,
  end: number
}

interface MediaMenuProps {
  selectedMedia: SelectedMediaType | null;
  canvasBackgroundColor: [number, number, number, number],
  mediaStart: number | null,
  mediaEnd: number | null,
  isMobileLayout: boolean;
  onMediaChanged: (media: Video, start: number | null, end: number | null) => void;
  onChangeBackground: (color: [number, number, number, number]) => void;
  onAddMedia: (file: File) => void;
}

const MediaMenu: React.FC<MediaMenuProps> = ({
  selectedMedia,
  isMobileLayout,
  onMediaChanged,
  onAddMedia,
}) => {
  const theme = useTheme();
  const [mediaAttributes, setMediaAttributes] = useState<MediaAttributes | null>(null);

  const defaultSpeed = 1;
  const defaultVolume = 1;
  const defaultStart = 0;
  const defaultEnd = 0;
  const defaultOpacity = 1;
  const defaultHue = 0;
  const defaultBrightness = 0;

  useEffect(() => {
    if (selectedMedia) {
      const speed = selectedMedia?.media?.speed || defaultSpeed;
      const volume = selectedMedia?.media?.volume || defaultVolume;
      const start = selectedMedia.start || defaultStart;
      const end = selectedMedia.end || defaultEnd;
      const opacity = selectedMedia.media instanceof Video ? selectedMedia.media.opacity || defaultOpacity : undefined;
      const hue = selectedMedia.media instanceof Video ? selectedMedia.media.hue || defaultHue : undefined;
      const brightness = selectedMedia.media instanceof Video ? selectedMedia.media.brightness || defaultBrightness : undefined;
      setMediaAttributes({
        speed,
        volume,
        start,
        end,
        opacity,
        hue,
        brightness
      });
    }
  //}, [selectedMedia, mediaStart, mediaEnd]);
  }, [selectedMedia]);

  const debouncedOnMediaChanged = useCallback(
      throttle((newMedia, start, end) => {
        // prevent the function from using the stale parameters (it's being memoized by useCallback)
        onMediaChanged(newMedia, start, end);
      }, 100),
      []
  );

  const triggerTimelineChange = (newStart: number, newEnd: number) => {
    if (selectedMedia && mediaAttributes && selectedMedia.media) {
        // User changed the end ?
        if (newEnd !== mediaAttributes.end) {
            // Do not allow increasing more than the maximum duration of the media
            if (newEnd - newStart > selectedMedia.media.duration) {
                return;
            }

        }
        // User changed the start ?
        else if (newStart !== mediaAttributes.start) {
            // Set the end according to the existing duration
            newEnd = newStart + (mediaAttributes.end - mediaAttributes.start);
        }
        if (selectedMedia.media instanceof Video) {
          onMediaChanged({...selectedMedia.media, ...mediaAttributes as any}, newStart, newEnd);
        }
        else if (selectedMedia.media instanceof Audio) {
          onMediaChanged({...selectedMedia.media as Video, ...mediaAttributes, hue: 0, brightness: 0, opacity: 0}, newStart, newEnd);
        }
    }
  };

  return (
    !selectedMedia ? (
      <EmptyMediaMenu onAddMedia={onAddMedia} />
    ) : (
    <Container>
        <Box style={{ direction: 'ltr' }}>
        <Typography
            variant={isMobileLayout ? 'h6' : 'h5'}
            gutterBottom
            sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
        >
            Video Settings
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />

        {selectedMedia.media && (
            <Box>
            {/* Playback Controls */}
            <Section elevation={3}>
                <SectionHeader>
                    <SpeedIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                    Playback Controls
                </SectionHeader>
                
                {/* Speed Control */}
                <Box mb={3}>
                    <ControlLabel>
                        <SpeedIcon sx={{ marginRight: 1 }} />
                        Speed
                    </ControlLabel>
                    {/* Preset Speed Buttons */}
                    <Box display="flex" flexWrap="wrap" flexGrow={1} gap={0.5}>
                    {/*TODO: need to support 0.5 (slower speeds...) - requires increasing the sizeof trails dynamically*/}
                    {[1, 1.5, 2, 4, 8].map((speed) => (
                        <SpeedButton
                        key={speed}
                        variant="contained"
                        size="small"
                        $active={(mediaAttributes ? mediaAttributes.speed : defaultSpeed) === speed}
                        onClick={() => debouncedOnMediaChanged({...selectedMedia.media as Video, ...mediaAttributes, speed}, selectedMedia.start, selectedMedia.end)}
                        >
                        {speed}x
                        </SpeedButton>
                    ))}
                    </Box>

                    <Box display="flex" alignItems="center">
                        <StyledSlider
                            value={mediaAttributes ? mediaAttributes.speed : defaultSpeed}
                            min={1}
                            max={8}
                            step={0.1}
                            onChange={(event, speed: number | number[]) => debouncedOnMediaChanged({...selectedMedia.media as Video, ...mediaAttributes, speed}, selectedMedia.start, selectedMedia.end)}
                            valueLabelDisplay="auto"
                            aria-labelledby="speed-slider"
                            sx={{ flexGrow: 1 }}
                        />
                        <Typography variant="body2" sx={{ ml: 2, width: '25px', minWidth: '25px', maxWidth: '25px' }}>
                            {mediaAttributes ? mediaAttributes.speed : defaultSpeed}x
                        </Typography>
                    </Box>
                </Box>

                {/* Volume Control */}
                <Box>
                    <ControlLabel>
                        <VolumeUpIcon sx={{ marginRight: 1 }} />
                        Volume
                    </ControlLabel>
                    <Box display="flex" alignItems="center">
                        <StyledSlider
                        value={Math.round((mediaAttributes ? mediaAttributes.volume : defaultVolume) * 100)}
                        min={0}
                        max={100}
                        step={1}
                        onChange={(event, newVolume) => onMediaChanged({...selectedMedia.media as Video, ...mediaAttributes, volume: newVolume as number / 100}, selectedMedia.start, selectedMedia.end)}
                        valueLabelDisplay="auto"
                        aria-labelledby="volume-slider"
                        sx={{ flexGrow: 1 }}
                        />
                        <Typography variant="body2" sx={{ ml: 2, width: '25px', minWidth: '25px', maxWidth: '25px' }}>
                        {Math.round((mediaAttributes ? mediaAttributes.volume : defaultVolume) * 100)}%
                        </Typography>
                    </Box>
                </Box>
            </Section>

            {/* Visual Adjustments */}
            {selectedMedia.media instanceof Video && (
            <Section elevation={3}>
                <SectionHeader>
                <ColorLensIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                Visual Adjustments
                </SectionHeader>
                <Grid container spacing={3}>
                {/* Brightness Control */}
                <Grid item xs={12} sm={4}>
                    <ControlLabel>
                    <Brightness5Icon sx={{ marginRight: 1 }} />
                    Brightness
                    </ControlLabel>
                    <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledSlider
                        value={mediaAttributes ? mediaAttributes.brightness : defaultBrightness}
                        min={-10}
                        max={10}
                        step={1}
                        onChange={(event, brightness: any) => onMediaChanged({...selectedMedia.media as Video, ...mediaAttributes, brightness}, selectedMedia.start, selectedMedia.end)}
                        valueLabelDisplay="auto"
                        aria-labelledby="brightness-slider"
                        sx={{ width: '100%' }}
                    />
                    <Typography variant="body2">{mediaAttributes ? mediaAttributes.brightness : defaultBrightness}%</Typography>
                    </Box>
                </Grid>

                {/* Opacity Control */}
                <Grid item xs={12} sm={4}>
                    <ControlLabel>
                    <OpacityIcon sx={{ marginRight: 1 }} />
                    Opacity
                    </ControlLabel>
                    <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledSlider
                        value={mediaAttributes ? mediaAttributes.opacity: defaultOpacity}
                        min={0}
                        max={1}
                        step={0.01}
                        onChange={(event, opacity: any) => onMediaChanged({...selectedMedia.media as Video, ...mediaAttributes, opacity}, selectedMedia.start, selectedMedia.end)}
                        valueLabelDisplay="auto"
                        aria-labelledby="opacity-slider"
                        sx={{ width: '100%' }}
                    />
                    <Typography variant="body2">{((mediaAttributes ? mediaAttributes.opacity || defaultOpacity : defaultOpacity) * 100).toFixed(0)}%</Typography>
                    </Box>
                </Grid>

                {/* Hue Control */}
                <Grid item xs={12} sm={4}>
                    <ControlLabel>
                    <ColorLensIcon sx={{ marginRight: 1 }} />
                    Hue
                    </ControlLabel>
                    <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledSlider
                        value={mediaAttributes ? mediaAttributes.hue : defaultHue}
                        min={-180}
                        max={180}
                        step={1}
                        onChange={(event, hue: any) => onMediaChanged({...selectedMedia.media as Video, ...mediaAttributes, hue}, selectedMedia.start, selectedMedia.end)}
                        valueLabelDisplay="auto"
                        aria-labelledby="hue-slider"
                        sx={{ width: '100%' }}
                    />
                    <Typography variant="body2">{mediaAttributes ? mediaAttributes.hue : defaultHue}°</Typography>
                    </Box>
                </Grid>
                </Grid>
            </Section>
            )}

            {/* Timeline Controls */}
            <Section elevation={3}>
                <SectionHeader>
                    <TimerIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                    Timeline Controls
                </SectionHeader>

                {/* Container for Start and End Time */}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    //justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    borderRadius: '8px',         // Rounded corners
                    border: 'solid 0.5px'
                }}>
                    
                    {/* Start Time Input with Play Icon */}
                    <Box sx={{
                        display: 'flex', 
                        alignItems: 'center', 
                        //backgroundColor: '#f9f9f9',  // Light background color for distinction
                        padding: 1,                  // Padding inside each section
                        borderRadius: '8px',         // Rounded corners
                        //border: 'solid 0.5px'
                    }}>
                        <ControlLabel sx={{margin:0}}>
                            <PlayArrowIcon  /> {/* Start Icon */}
                            <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '1.75', marginLeft: '5px'}} >Start</Typography>
                            <TimeInput value={mediaAttributes ? mediaAttributes.start : defaultStart} onChange={(newStart) => triggerTimelineChange(newStart, mediaAttributes ? mediaAttributes.end : defaultEnd)} />
                        </ControlLabel>
                    </Box>

                    {/* Vertical Divider (Separator) */}
                    <Divider
                        orientation="vertical" // Make it vertical
                        flexItem               // Ensure it takes up the height of the surrounding content
                        sx={{ backgroundColor: '#eee', width: '1px', margin: '8px'}} // Styling
                    />
                    
                    {/* End Time Input with Stop Icon */}
                    <Box sx={{
                        display: 'flex', 
                        alignItems: 'center', 
                        //backgroundColor: '#f9f9f9',  // Light background color for distinction
                        padding: 1,
                        borderRadius: '8px',
                        //border: 'solid 0.5px'
                    }}>
                        <ControlLabel sx={{margin:0}}>
                            <StopIcon /> {/* End Icon */}
                            <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '1.75', marginLeft: '5px'}} >End</Typography>
                            <TimeInput
                              value={mediaAttributes ? mediaAttributes.end : defaultEnd}
                              maxValue={(mediaAttributes ? mediaAttributes.start : defaultStart) + selectedMedia?.media.duration}
                              onChange={(newEnd) => triggerTimelineChange(mediaAttributes ? mediaAttributes.start : defaultStart, newEnd)}
                            />
                        </ControlLabel>
                    </Box>
                </Box>
            </Section>
            </Box>
        )}
        </Box>
    </Container>
    )
  );
};

export default MediaMenu;
