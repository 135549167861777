import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Box,
  useTheme,
  Slider,
  Divider,
  Button,
  Paper,
} from '@mui/material';
import { debounce } from 'lodash';
import { styled } from '@mui/material/styles';
import SpeedIcon from '@mui/icons-material/Speed';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import TimerIcon from '@mui/icons-material/Timer';
import MicIcon from '@mui/icons-material/Mic';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TranscribeIcon from '@mui/icons-material/GraphicEq';
import Video from '../media/Video';
import Audio from '../media/Audio';
import TimeInput from '../TimeInput';
import { useDropzone } from 'react-dropzone';

// Styled components
const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  height: '100%',
  overflowY: 'auto',
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

const ControlLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: theme.palette.grey[400],
  },
}));

interface SpeedButtonProps {
  $active: boolean;
}

const SpeedButton = styled(Button)<SpeedButtonProps>(({ theme, $active }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: $active ? theme.palette.primary.main : theme.palette.grey[300],
  color: $active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: $active ? theme.palette.primary.dark : theme.palette.grey[400],
  },
  minWidth: 50,
}));

const DropzoneArea = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

type MediaAttributes = {
  speed: number;
  volume: number;
  start: number;
  end: number;
};

interface AudioMenuProps {
  selectedMedia: Video | Audio | null;
  mediaStart: number | null;
  mediaEnd: number | null;
  isMobileLayout: boolean;
  onMediaChanged: (media: Video, start: number | null, end: number | null) => void;
  onAddMedia: (file: File) => void;
  onTranscribe: (media: Audio | Video) => void;
  onRecord: () => void;
}

const AudioMenu: React.FC<AudioMenuProps> = ({
  selectedMedia,
  mediaStart,
  mediaEnd,
  isMobileLayout,
  onMediaChanged,
  onAddMedia,
  onTranscribe,
  onRecord,
}) => {
  const theme = useTheme();
  const [mediaAttributes, setMediaAttributes] = useState<MediaAttributes>({
    speed: 1,
    volume: 1,
    start: 0,
    end: 0,
  });

  useEffect(() => {
    if (selectedMedia) {
      setMediaAttributes({
        speed: selectedMedia.speed || 1,
        volume: selectedMedia.volume || 1,
        start: mediaStart || 0,
        end: mediaEnd || 0,
      });
    }
  }, [selectedMedia, mediaStart, mediaEnd]);

  const debouncedMediaChanged = useCallback(
    debounce((updatedAttributes: MediaAttributes) => {
      if (selectedMedia) {
        onMediaChanged({ ...selectedMedia as Video, ...updatedAttributes }, updatedAttributes.start, updatedAttributes.end);
      }
    }, 300),
    [selectedMedia]
  );

  const handleAttributeChange = (attribute: keyof MediaAttributes, value: number) => {
    const updatedAttributes = { ...mediaAttributes, [attribute]: value };
    setMediaAttributes(updatedAttributes);
    debouncedMediaChanged(updatedAttributes);
  };

  const triggerTimelineChange = (newStart: number, newEnd: number) => {
    if (selectedMedia) {
      const updatedAttributes = { ...mediaAttributes, start: newStart, end: newEnd };
      setMediaAttributes(updatedAttributes);
      debouncedMediaChanged(updatedAttributes);
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onAddMedia(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'audio/*': [] },
    multiple: false,
  });

  return (
    <Container>
      <Box style={{ direction: 'ltr' }}>
        <Typography
          variant={isMobileLayout ? 'h6' : 'h5'}
          gutterBottom
          sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
        >
          Audio Settings
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />

        {selectedMedia ? (
          <Box>
            <Section elevation={3}>
              <SectionHeader>
                <SpeedIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                Playback Controls
              </SectionHeader>
              <Box mb={3}>
                <ControlLabel>
                  <SpeedIcon sx={{ marginRight: 1 }} />
                  Speed
                </ControlLabel>
                <Box display="flex" flexWrap="wrap" flexGrow={1} gap={0.5}>
                  {[1, 1.5, 2, 4, 8].map((preset) => (
                    <SpeedButton
                      key={preset}
                      variant="contained"
                      size="small"
                      $active={mediaAttributes.speed === preset}
                      onClick={() => handleAttributeChange('speed', preset)}
                    >
                      {preset}x
                    </SpeedButton>
                  ))}
                </Box>

                <Box display="flex" alignItems="center">
                  <StyledSlider
                    value={mediaAttributes.speed}
                    min={0.5}
                    max={8}
                    step={0.1}
                    onChange={(event, value) => handleAttributeChange('speed', value as number)}
                    valueLabelDisplay="auto"
                    aria-labelledby="speed-slider"
                    sx={{ flexGrow: 1 }}
                  />
                  <Typography variant="body2" sx={{ ml: 2, width: '35px' }}>
                    {mediaAttributes.speed}x
                  </Typography>
                </Box>
              </Box>

              <Box>
                <ControlLabel>
                  <VolumeUpIcon sx={{ marginRight: 1 }} />
                  Volume
                </ControlLabel>
                <Box display="flex" alignItems="center">
                  <StyledSlider
                    value={Math.round(mediaAttributes.volume * 100)}
                    min={0}
                    max={100}
                    step={1}
                    onChange={(event, value) => handleAttributeChange('volume', (value as number) / 100)}
                    valueLabelDisplay="auto"
                    aria-labelledby="volume-slider"
                    sx={{ flexGrow: 1 }}
                  />
                  <Typography variant="body2" sx={{ ml: 2, width: '35px' }}>
                    {Math.round(mediaAttributes.volume * 100)}%
                  </Typography>
                </Box>
              </Box>
            </Section>

            <Section elevation={3}>
              <SectionHeader>
                <TranscribeIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                Transcribe Audio
              </SectionHeader>
              <Typography variant="body1" gutterBottom>
                Convert the audio into text using our transcription service.
              </Typography>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  disabled
                  variant="contained"
                  startIcon={<TranscribeIcon />}
                  onClick={() => selectedMedia && onTranscribe(selectedMedia)}
                  sx={{ minWidth: 200 }}
                >
                  Transcribe Audio
                </Button>
              </Box>
            </Section>

            <Section elevation={3}>
              <SectionHeader>
                <TimerIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                Timeline Controls
              </SectionHeader>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', borderRadius: '8px', border: 'solid 0.5px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, borderRadius: '8px' }}>
                  <ControlLabel sx={{ margin: 0 }}>
                    <PlayArrowIcon />
                    <Typography variant="body1" sx={{ fontSize: '14px', marginLeft: '5px' }}>Start</Typography>
                    <TimeInput value={mediaAttributes.start} onChange={(value) => triggerTimelineChange(value, mediaAttributes.end)} />
                  </ControlLabel>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#eee', width: '1px', margin: '8px' }} />
                <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, borderRadius: '8px' }}>
                  <ControlLabel sx={{ margin: 0 }}>
                    <StopIcon />
                    <Typography variant="body1" sx={{ fontSize: '14px', marginLeft: '5px' }}>End</Typography>
                    <TimeInput value={mediaAttributes.end} maxValue={mediaAttributes.start + selectedMedia?.duration || 0} onChange={(value) => triggerTimelineChange(mediaAttributes.start, value)} />
                  </ControlLabel>
                </Box>
              </Box>
            </Section>
          </Box>
        ) : (
          <Box>
            <Section elevation={3}>
              <SectionHeader>
                <CloudUploadIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                Upload Audio
              </SectionHeader>
              <DropzoneArea {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadFileIcon sx={{ fontSize: 64, color: theme.palette.primary.main }} />
                <Typography variant="h6" gutterBottom>Drag & Drop Audio Files Here</Typography>
                <Typography variant="body1">or click to browse your files</Typography>
              </DropzoneArea>
            </Section>

            <Section elevation={3}>
              <SectionHeader>
                <MicIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                Record Audio
              </SectionHeader>
              <Typography variant="body1" gutterBottom>
                Capture audio directly from your microphone.
              </Typography>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  disabled
                  variant="contained"
                  startIcon={<MicIcon />}
                  onClick={onRecord}
                  sx={{ minWidth: 200 }}
                >
                  Start Recording
                </Button>
              </Box>
            </Section>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AudioMenu;
